html {
  min-height: 100%;
}

body, body > div {
  min-height: 100vh;
}

html, body, div, p, img, picture, figure {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Merriweather, sans-serif;
  font-weight: 300;
}

html, body {
  font-family: Open Sans, sans-serif;
  font-weight: 300;
}

a, a:visited, a:active {
  color: #000;
  text-decoration: none;
}

/*# sourceMappingURL=index.c2c8a68a.css.map */
